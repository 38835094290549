.modal-gallery-box  {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-gallery-item > * {
    width: 100% !important;
    height: auto;
    display: block;
    max-height: 835px;
}